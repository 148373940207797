import { adjustButtonsMargins,animButtons, addSvgToLinks } from './functions.js'
/*
|--------------------------------------------------------------------------
| Helper
|--------------------------------------------------------------------------
|
| Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
*/

export function dynamicPackage() {
    forceBlankOnExterneAndPdfLinks()
    adjustButtonsMargins()
    animButtons()
    addSvgToLinks()
    removeHoverOnMobile()
}

// Retourne si l'utilisateur est sur mobile ou pas
export const isMobile = () => {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}

export function forceBlankOnExterneAndPdfLinks() {
  var anchors = document.querySelectorAll('a[href]')
  for (var i = 0; i < anchors.length; i++) {

    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;')
    {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
      {
        anchors[i].setAttribute('target', '_blank')
      }
    }
  }
}

// Retourne la position d'un élément dans le DOM
export const getElementOffset = (options) => {
  options.root === undefined ? options.root = document : options.root
  let box = typeof options.element === 'string' ? options.root.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

// Retourne le nombre d'octets formaté
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


// Création d'un délai
export const delay = (number) => {
  number = number || 2000

  return new Promise(resolve => {
    setTimeout(resolve, number)
  })
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}

// Permet de véréfier si un élément est visible à l'écran
export const isVisible = (e, threshold, mode) => {
  threshold = threshold || 0
  mode = mode || 'visible'

  let element = document.querySelector(e)
  let rect = element.getBoundingClientRect()
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  let above = rect.bottom - threshold < 0
  let below = rect.top - viewHeight + threshold >= 0

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below)
}
