/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/

export const slickBanner = (root = document) => {

  $(root).find('.js-slick-home-banner-images').slick({
    dots: false,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '.js-slick-home-banner-texts',
  })

  $(root).find('.js-slick-home-banner-texts').slick({
    dots: false,
    arrows: true,
    appendArrows: '.js-slick-home-banner-arrows',
    prevArrow: '<div class="home-slider__nav-arrows-prev"><svg class="home-slider__nav-arrows-svg"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="home-slider__nav-arrows-next"><svg class="home-slider__nav-arrows-svg"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '.js-slick-home-banner-images',
  })

  slickSlideCount('.js-slick-home-banner-images', '.js-slick-home-banner-current', '.js-slick-home-banner-total')

  let vid = document.querySelector('.js-video-autoplay')
  if(vid){
    if(window.innerWidth >= 768)
      vid.play()
  }

}


export const slickNews = (root = document) => {

  $(root).find('.js-slick-news').slick({
    nextArrow: '<div class="slick-next slick-arrow"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    dots: false,
    arrows: true,
    slidesToShow: 2,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 0, //6000
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  })

}


// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, totalElement)  {
  $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $(currentElement).html(slidePosition)
  })

  let total = $(slickElement + ' .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total < 10 ? '0' + total : total
  $(totalElement).html(displayedTotal)
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick() {
  $('.slick-initialized').slick('unslick')
}


// Création du slick de la bannière
export function slickAlerts() {
  if (document.querySelectorAll('.js-mobile-alerts-slider').length != 0) {
    $(document).find('.js-mobile-alerts-slider').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
    })

    let total = $(document).find('.js-mobile-alerts-slider .slick-slide:not(.slick-cloned)').length
    let displayedTotal = total < 10 ? + total : total
    $(document).find('.js-mobile-alerts-total').html(displayedTotal)

    $(document).find('.js-mobile-alerts-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $(document).find('.js-mobile-alerts-current').html(slidePosition)
    })
  }
}