/*
|--------------------------------------------------------------------------
| Les vues
|--------------------------------------------------------------------------
|
| Attribution des scripts à éxécuter sur chaque page selon leurs vues
*/

// Importation ----------------------------------------------------------------------------------------------------
import Overlay from '../libraries/overlay.js'
import { OBSERVER, PARALLAX, SCROLLFIRE } from './main.js'
import { dynamicPackage } from './functions/helper.js'
import { slugifyProvider, clickToScrollToBlock, customCursor, initBackToTop, alerts, adresseSearchResultsLoaded, adresseSearchAutocomplete, dragAndSlide, recaptcha } from './functions/functions.js'
import { overlayDropdown, overlayBreadcrumbs, overlayCalendar, overlayMenu, overlaySearch, overlayShare, overlayPopup } from './functions/overlays.js'
import { slickBanner, slickNews, slickAlerts } from './functions/sliders.js'
import { fileUpload, select, textareaHeight, clearInput, inputsAndTextareaLabel, formAddressSearch, formContact, formSearch, formCarreer, formNewsletter } from './functions/form.js'
import { masksPackage } from './functions/masks.js'
import { mapPinMobile, googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers, mapHeight } from './functions/map.js'
import { Accordions } from './functions/accordions.js'

window.recaptcha = recaptcha
window.slugifyProvider = slugifyProvider
window.adresseSearchResultsLoaded = adresseSearchResultsLoaded
window.dynamicPackage = dynamicPackage


// Initialisation sur toutes les pages
export class View {
  static init(next) {
    // global init
    dynamicPackage()
    formSearch('#form-search', '#submit-search')

    initBackToTop()

    alerts()
    slickAlerts()

    overlayDropdown()
    overlayMenu()
    overlaySearch()
    overlayShare()
    overlayPopup()

    SCROLLFIRE.init()
    PARALLAX.init([
      {
        target: '.js-parallax-wave1',
        parallax: [-0.14, 0.14, 0, 0, 0, 1]
      },
      {
        target: '.js-parallax-wave2',
        parallax: [0.14, -0.14, 0, 0, 0, 1]
      },
      {
        target: '.js-parallax-showcase',
        parallax: [0, 0, 0, -0.08, 0.08, 1]
      },
      //Section liens carte intéractive et recherche par adresse
      {
        target: '.js-parallax-card1',
        parallax: [0, 0, -0.06, 0.06, 0, 1]
      },
      {
        target: '.js-parallax-card2',
        parallax: [0, 0, 0.06, -0.06, 0, 1]
      },
    ])

    inputsAndTextareaLabel()
    clearInput()
  }

  static leave() {
    // global leave
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
  }
}

// Initialisation sur la page générique
export class Generic extends View {
  static initGeneric(next) {
    this.init(next)
    select()
    textareaHeight()
    fileUpload()
    overlayBreadcrumbs()
    slickNews()
    masksPackage()

    initFormBuilder()
    //recaptchaFormsCallback()


    PARALLAX.init([
      {
        target: '.js-parallax-intro',
        parallax: [0, 0, -0.08, 0.08, 0, 1]
      },
      {
        target: '.js-parallax-events-1',
        parallax: [0, 0, 0.2, -0.2, 0, 1]
      },
      {
        target: '.js-parallax-events-2',
        parallax: [0, 0, 0.1, -0.1, 0, 1]
      }
    ])

    new Accordions()
  }

  static leaveGeneric(current) {
    this.leave(current)
    //destroySlick(current.container)
  }
}


// Initialisation sur la page d'accueil
export class Home extends View {
  static initHome(next) {
    this.init(next)

    slickBanner()
    slickNews()

    clickToScrollToBlock({ selector: '.js-scroll-down', scrollTo: '.js-scroll-down-target', offset: 0 ,root: next.container })

    PARALLAX.init([
      {
        target: '.js-parallax-home-slider',
        parallax: [0.10, -0.18, 0, 0, 0, 1], //Horizontal
      },
      {
        target: '.js-parallax-home-slide-image',
        parallax: [0, 0, -0.08, 0.08, 0, 1]
      },
      {
        target: '.js-parallax-fast-links',
        parallax: [0, 0, -0.12, 0.12, 0, 1]
      },
      //Section Actualité
      {
        target: '.js-parallax-back-to-top',
        parallax: [0, 0, -0.4, 0.4, 0, 1]
      },
      //Section Événements
      {
        target: '.js-parallax-events-1',
        parallax: [0, 0, 0.2, -0.2, 0, 1]
      },
      {
        target: '.js-parallax-events-2',
        parallax: [0, 0, 0.1, -0.1, 0, 1]
      },
      {
        target: '.js-parallax-events-3',
        parallax: [0.3, -0.3, 0, 0, 0, 1]
      },
    ])



  }

  static leaveHome(current) {
    this.leave(current)
    //destroySlick(current.container)
  }
}


// Initialisation sur la page de liste de nouvelles
export class News extends View {
  static initNews(next) {
    this.init(next)
    customCursor()
  }

  static leaveNews(current) {
    this.leave(current)

  }
}


// Initialisation sur la page de détail de nouvelles
export class NewsDetail extends View {
  static initNewsDetail(next) {
    this.init(next)
    slickNews()

    PARALLAX.init([{
      target: '.js-parallax-intro',
      parallax: [0, 0, -0.08, 0.08, 0, 1]
    }])
  }

  static leaveNewsDetail(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de liste d'événements
export class Events extends View {
  static initEvents(next) {
    this.init(next)
    customCursor()
    overlayCalendar()

    PARALLAX.init([
      //Section Événements
      {
        target: '.js-parallax-events-1',
        parallax: [0, 0, 0.2, -0.2, 0, 1]
      },
      {
        target: '.js-parallax-events-2',
        parallax: [0, 0, 0.1, -0.1, 0, 1]
      },
      {
        target: '.js-parallax-events-3',
        parallax: [0.3, -0.3, 0, 0, 0, 1]
      },
    ])
  }

  static leaveEvents(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de détail d'événement
export class Event extends View {
  static initEvent(next) {
    this.init(next)

    PARALLAX.init([
      {
        target: '.js-parallax-intro',
        parallax: [0, 0, -0.08, 0.08, 0, 1]
      },
      {
        target: '.js-parallax-events-1',
        parallax: [0, 0, 0.2, -0.2, 0, 1]
      },
      {
        target: '.js-parallax-events-2',
        parallax: [0, 0, 0.1, -0.1, 0, 1]
      },
      {
        target: '.js-parallax-events-3',
        parallax: [0.3, -0.3, 0, 0, 0, 1]
      }
    ])

  }

  static leaveEvent(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de détail d'événement
export class AddressSearch extends View {
  static initAddressSearch(next) {
    this.init(next)

    new Accordions()
    formAddressSearch()
    adresseSearchAutocomplete()
  }

  static leaveAddressSearch(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de résultat de recherche
export class Search extends View {
  static initSearch(next) {
    this.init(next)

    formSearch('#search-page-form', '#search-page-submit')
    dragAndSlide({ name: 'filter' })
  }

  static leaveSearch(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de liste de d'emplois
export class Jobs extends View {
  static initJobs(next) {
    this.init(next)

    formCarreer()
    fileUpload()
    dragAndSlide({ name: 'filter' })
  }

  static leaveJobs(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de détail d'emploi
export class Job extends View {
  static initJob(next) {
    this.init(next)

    formCarreer()
    fileUpload()
  }

  static leaveJob(current) {
    this.leave(current)
  }
}


// Initialisation sur la page de la carte interactive
export class Map extends View {
  static initMap(next) {
    overlayLocation()
    overlayLocations()
    goBackMap()
    subMenu()
    drawers()
    googleMap()
    mapHeight()
  }

  static leaveMap() {
    this.leave()
    destroyMap()
  }
}



// Initialisation sur la page de détail d'événement
export class Contact extends View {
  static initContact(next) {
    this.init(next)

    new Accordions()
    textareaHeight()
    formContact()
    fileUpload()
    clickToScrollToBlock({ selector: '.js-scroll-to-form', scrollTo: '.js-form-contact', offset: -120 ,root: next.container })
  }

  static leaveContact(current) {
    this.leave(current)
  }
}

// Initialisation sur la page d'inscription à l'infolettre
export class Newlestter extends View {
  static initNewlestter(next) {
    this.init(next)
    formNewsletter()
  }

  static leaveNewlestter(current) {
    this.leave(current)
  }
}
