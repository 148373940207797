/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { Accordions } from './accordions.js'
import { copyTextToClipboard, calendar, scrollToBlock } from './functions.js'


// Fonction gérant l'overlay Search
export const overlayDropdown = (root = document) => {

  // Ajuster la taille es section de menus selon la section active
  const setHeightSubSections = (e) => {
    let section,height = 0
    section = e.target.dataset.section
    height = root.querySelector('.js-dropdown-section[data-section="'+section+'"]').offsetHeight
    root.querySelector('.js-menu-wrapper').style.height = `${height}px`
  }

  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown, .overlay-dropdown__background', switch: '.js-toggle-overlay-search' }},
    animations: {
      selector: '.js-dropdown-container',
      styles: [{ property: 'height', value: `100vh`, easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      root: root
    }
  })

  dropdown.init()

  OBSERVER.add({ name: 'setHeightSubSections', event: 'click', function: setHeightSubSections, target: '.js-trigger-overlay-dropdown' })
  OBSERVER.on('setHeightSubSections')
}


// Fonction gérant l'overlay Search
export const overlaySearch = (root = document) => {
  let search = new Overlay({
    name: 'search',
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-trigger-overlay-dropdown, .js-toggle-overlay-menu', close: '.js-close-overlay-search' }},
    animations: {
      selector: '.js-search-container',
      styles: [{ property: 'bottom', value: `0%`, easing: 'easeInOutQuart' }]
    },
    events: {
      openComplete: true,
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false
    }
  })
  
  search.init()

  addEventListener('onOpenCompleteOverlaySearch', giveFocusToSearch)

  function giveFocusToSearch(){
    root.querySelector('.js-input-to-clear').focus()
  }



  activateWithKeyUp('#form-search #form-search-input', '#autocomplete', true)

  function activateWithKeyUp(input, selector, htmlClass = false) {

    var ajaxRequest = false

    $(input).keyup(function(e) {

      if( ajaxRequest ) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort()
      }

      var keycode = (e.keyCode ? e.keyCode : e.which)

      if ( keycode != 13 ) {
        if ($(this).val().length >= 4) {
          console.log('show')

          ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
            data: { q: $(this).val() },
            update: { 'rechercheAutocomplete::autocomplete':'#overlay-search #autocomplete'},
            complete: function (data) {
              // Results loaded.
              ajaxRequest = false
              //$('#fastResults').stop(true, false).animate({ height: 'show' }, { duration: 300, specialEasing: { height: 'easeOutExpo' }})
              //$(selector).stop(true, false).animate({height: 'show'}, {
              $(selector).animate({height: 'show'}, {
                duration: 500,
                specialEasing: {height: 'easeOutExpo'}
              })
            },
          })

          if (htmlClass) $('html').addClass('showOverlaySearch noScroll')
        } else {
          console.log('hide')
          $(selector).stop(true, false).animate({height: 'hide'}, {
            duration: 500, specialEasing: {height: 'easeOutExpo'}, complete: function () {
              $(selector).css('height', '')
            }
          })
          if (htmlClass) $('html').removeClass('showOverlaySearch noScroll')
        }
      }
    })
  }
}


// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  share.init()

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $('.rubric-share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Ville de Sorel-Tracy&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Ville de Sorel-Tracy&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}


// Fonction gérant l'overlay Search
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    click: { buttons: { trigger: '.js-level-1-nav', close:'.js-close-overlay,.js-close-overlay-menu', toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root
    },
    events: {
      close: true,
    }
  })
  addEventListener('onCloseOverlayMenu', resetSecondLevelHeighs)
  menu.init()

  // Bouton retour dans le menu 2e niveau
  const setMenuBackButton = () => {
    let body = root.querySelector('html')

    // Retirer les hauteurs
    resetSecondLevelHeighs()

    // Reslider à au premier niveau
    body.removeAttribute('data-section')
  }
  OBSERVER.add({
    name: 'setMenuBackButton',
    event: 'click',
    function: setMenuBackButton,
    target: '.js-menu-back',
    root: root
  })
  OBSERVER.on('setMenuBackButton')

  // Ajuster hauteur du menu 2e niveau
  const setLevel2Height = (e) => {
    let section = document.querySelector('html').dataset.section
    let activeSection = document.querySelector('.js-level-2-section[data-section="'+ section +'"]')
    let height = 0
    height += activeSection.querySelector('.overlay-menu__level-2-top').offsetHeight
    height += activeSection.querySelector('.overlay-menu__level-2-nav').offsetHeight
    activeSection.style.height = height+'px';
  }
  OBSERVER.add({
    name: 'setLevel2Height',
    event: 'click',
    function: setLevel2Height,
    target: '.js-level-1-nav',
    root: root
  })
  OBSERVER.on('setLevel2Height')

  function resetSecondLevelHeighs(){
    // Retirer les hauteurs
    var sectionsList = document.querySelectorAll('.js-level-2-section'), i;
    for (i = 0; i < sectionsList.length; ++i) {
      sectionsList[i].style.height = 0;
    }
  }

}


// Fonction gérant l'overlay Breadcrumbs
export const overlayBreadcrumbs = (root = document) => {

  let breadcrumbs = root.querySelector('.js-breadcrumbs-drop')
  let height = breadcrumbs.offsetHeight
  root.querySelector('.js-breadcrumbs-drop').dataset.height = `${height}px`

  const setOverlayBreadcrumbs = () => {
    let breadcrumbs = root.querySelector('.js-breadcrumbs')
    breadcrumbs.classList.toggle('breadcrumbs--open')

    let targetHeight = 0
    if(breadcrumbs.classList.contains('breadcrumbs--open')){
      targetHeight = breadcrumbs.querySelector('.js-breadcrumbs-drop').dataset.height
    }

    root.querySelector('.js-breadcrumbs-drop').parentElement.style.height = targetHeight
  }

  OBSERVER.add({
    name: 'setOverlayBreadcrumbs',
    event: 'click',
    function: setOverlayBreadcrumbs,
    target: '.js-toggle-breadcrumbs',
    root: root
  })
  OBSERVER.on('setOverlayBreadcrumbs')

}

// Fonction gérant l'overlay Calendrier
export const overlayCalendar = (root = document) => {

  calendar()//Initialiser le calendrier à l'intérieur de l'overlay

  const setOverlayCalendar = () => {
    let body = root.querySelector('html')
    body.classList.toggle('calendar--open')

    scrollToBlock({
      scrollTo: '.js-toggle-calendar',
      offset: -40
    })
  }

  const closeOverlayCalendar = () => {
    let body = root.querySelector('html')
    body.classList.remove('calendar--open')
  }

  OBSERVER.add({
    name: 'setOverlayCalendar',
    event: 'click',
    function: setOverlayCalendar,
    target: '.js-toggle-calendar',
    root: root
  })
  OBSERVER.on('setOverlayCalendar')

  OBSERVER.add({
    name: 'closeOverlayCalendar',
    event: 'click',
    function: closeOverlayCalendar,
    target: '.js-close-calendar',
    root: root
  })
  OBSERVER.on('closeOverlayCalendar')

}

// Fonction gérant l'overlay popup
export function overlayPopup(root = document) {
  let popup = new Overlay({
    name: 'popup',
    create: {
      background: false
    },
    timeout: {
      delay: 300,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  popup.init()
}