/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/
import $ from 'jquery'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER } from './../main.js'
import { getElementOffset,isVisible } from './helper.js'
import { Accordions } from './accordions.js'

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
    root: options.root
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })

  return animation.finished
}

window.scrollToBlock = scrollToBlock

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $( '.dynamic p > a.btn, .dynamic p > a.btn--secondary' ).each(function() {
    $(this).parent().addClass('buttonMargin')
  })
}

// Ajouter un <span> dans les boutons pour l'animation
export function animButtons() {
  let buttons = $('.btn, .btn--secondary, .js-hover-btn')
  buttons.wrapInner('<span></span>')
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {

  let linksExternal = document.querySelectorAll('.dynamic a:not(.btn--map)[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/core/assets/medias/images/icons/symbols.svg#ico-external')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/core/assets/medias/images/icons/symbols.svg#ico-download')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

  let linksSecondaryButtons = document.querySelectorAll('.dynamic a.btn--secondary:not([target=_blank]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksSecondaryButtons.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

}

export function copyTextToClipboard(text) {
  // if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  // }
  // navigator.clipboard.writeText(text).then(function() {
  //   console.log('Async: Copying to clipboard was successful!')
  // }, function(err) {
  //   console.error('Async: Could not copy text: ', err)
  // })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

export const printPageOnClick = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  options.target === undefined ? options.target = '.js-print-page' : options.target

  OBSERVER.add({
    name: 'printPage',
    event: 'click',
    target: options.target,
    root: options.root,
    function: printPage
  })

  OBSERVER.on('printPage')
}
function printPage(){
  window.print()
}

export function selectProductOnClick(options = {}) {
  options.root === undefined ? options.root  = document : options.root
  options.target === undefined ? options.target = '.js-buy-online-select' : options.target
  options.button === undefined ? options.button = '.js-buy-online-button' : options.button

  const onChange = () => {
    let button = $(options.button)
    button.attr('href', $(options.target).val())
  }

  OBSERVER.add({
    name: 'productSelected',
    event: 'change',
    target: options.target,
    root: options.root,
    function: onChange
  })

  OBSERVER.on('productSelected')
}


export const initBackToTop = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root

  OBSERVER.add({
    name: 'manageBackToTop',
    event: 'scroll',
    root: options.root,
    function: manageBackToTop
  })

  clickToScrollToBlock({ selector: '.js-back-to-top', scrollTo: 'header' })
  manageBackToTop()

  OBSERVER.on('manageBackToTop')
}

function manageBackToTop(){
  let headerVisible = document.documentElement.scrollTop <= 600;
  if(!headerVisible){
    document.querySelector('html').classList.add("show-back-to-top")
  }else{
    document.querySelector('html').classList.remove("show-back-to-top")
  }
}


// Fonction gèrant l'image à afficher dans le dropdown
export function displayImages(options = {}) {
  options.root === undefined ? options.root  = document : options.root
  //options.container === undefined ? options.container  = '.js-drop-product' : options.root

  let i, images, imagesLength
  let doc = options.root

  OBSERVER.add({
    name: 'displayImages',
    event: 'mouseenter',
    root: options.root,
    target: options.container + ' .js-dropdown-image',
    function: mouseenter
  })
  OBSERVER.on('displayImages')

  OBSERVER.add({
    name: 'displayImages',
    event: 'mouseleave',
    root: options.root,
    target: options.container + ' .js-dropdown-image',
    function: mouseleave
  })
  OBSERVER.on('displayImages')


  function mouseenter(e) {
    if(typeof e.target.dataset.image !== "undefined"){
      let imagePath = options.container +' img[src=\''+ e.target.dataset.image +'\']'
      doc.querySelector(imagePath).classList.add('active')
    }
  }

  function mouseleave(e) {
    images = doc.querySelectorAll(options.container + ' .overlay-dropdown__picture img')
    imagesLength = images.length
    // Retirer la classe active partout
    for (i=0; i<imagesLength; i++)
      images[i].classList.remove('active')

    doc.querySelector(options.container + ' .js-drop-image-default').classList.add('active')
  }
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}

// Fonction permettant d'activer le calendrier
export const calendar = (root = document) => {
  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date
  console.log(eventsDatesList)

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) {
        data.then(function(data){ barba.go('/evenements/1/' + data['date'])})
        let body = root.querySelector('html')
        body.classList.remove('calendar--open')
      },
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)
}


export function customCursor(){

  // Placer le curseur en dehors du viewport
  let clientX = -100
  let clientY = -100
  let innerCursor
  //const filterWithCursor = document.querySelector(".js-follow-mouse-container")


  const initCursor = () => {

    OBSERVER.add({
      name: 'initFilterCursor',
      event: 'mousemove',
      target: '.js-follow-mouse-container',
      function: moveCursor
    })
    OBSERVER.on('initFilterCursor')

    function moveCursor(e) {
      innerCursor = e.target.querySelector(".js-custom-cursor")
      clientX = e.offsetX
      clientY = e.offsetY
      //clientX = e.clientX - (e.target.getBoundingClientRect().left+window.scrollX)
      //clientY = e.clientY - (e.target.getBoundingClientRect().top+window.scrollY)

      innerCursor.style.transform = `translate3d(${clientX}px, ${clientY}px, 0px)`
    }
  }

  initCursor()
}

// Fonction gérant les alertes
export function alerts() {
  const desktop = e => anime({ targets: e.currentTarget.parentNode, height: '0px', easing: 'easeOutExpo', duration: 400 })
  const mobile = e => anime({ targets: e.currentTarget.parentNode.parentNode, height: '0px', easing: 'easeOutExpo', duration: 400 })

  OBSERVER.add({ name: 'alerts', event: 'click', function: desktop, target: '.js-desktop-alerts-close' })
  OBSERVER.add({ name: 'alerts', event: 'click', function: mobile,  target: '.js-mobile-alerts-close' })
  OBSERVER.on('alerts')
}

export function adresseSearchAutocomplete() {

  var numberAutocompleteOptions = {
    noCache: true,
    params: { 'rue' : '' },
    serviceUrl: '/blanko/addrsearch/autocomplete/civic',
    appendTo: '#formAddressSearch-number-wrapper'
  }

  $('#formAddressSearch-number').autocomplete(numberAutocompleteOptions)

  $('#formAddressSearch-address').autocomplete({
    serviceUrl: '/blanko/addrsearch/autocomplete/street',
    appendTo: '#formAddressSearch-address-wrapper',
    onSelect: function (suggestion) {
      numberAutocompleteOptions.params = { 'rue' : this.value }
      $('#formAddressSearch-number').autocomplete().setOptions(numberAutocompleteOptions)
    }
  })

  $('#formAddressSearch-address').keyup( function() {
    numberAutocompleteOptions.params = { 'rue' : $('#formAddressSearch-address').val() }
    $('#formAddressSearch-number').autocomplete().setOptions(numberAutocompleteOptions)
  })
}

export function adresseSearchResultsLoaded() {
  new Accordions()
  animButtons()
  $('html, body').delay(200).animate({ scrollTop: ($('#searchResultsWrapper').offset().top - 80) }, 600, 'easeInOutExpo')
}


// ---------------------------------------------------------------------------
// Fonction permettant de créer un navigation horizontale en slidant
// ---------------------------------------------------------------------------
export function dragAndSlide(options = {}) {
  options.nameClass === undefined ? options.nameClass = 'drag-and-slide' : options.nameClass
  options.containerClass === undefined ? options.containerClass = 'js-drag-and-slide' : options.containerClass
  options.contentClass === undefined ? options.contentClass = 'js-drag-and-slide-content' : options.contentClass
  options.prevClass === undefined ? options.prevClass = 'js-drag-and-slide-prev' : options.prevClass
  options.nextClass === undefined ? options.nextClass = 'js-drag-and-slide-next' : options.nextClass

  const doc = document,
    container = doc.querySelector(`.${options.containerClass}`),
    content = doc.querySelector(`.${options.contentClass}`),
    prev = doc.querySelector(`.${options.prevClass}`),
    next = doc.querySelector(`.${options.nextClass}`),
    links = doc.querySelectorAll(`.${options.contentClass} a`),
    linksLength = links.length

  let i,
    startX,
    timeoutPreventDefault,
    scrollLeft,
    navigationIsDown = false,
    direction = 'right',
    isDown = false,
    preventDefault = false

  // Empêcher de drag les liens
  for (i = 0; i < linksLength; i++)
    links[i].ondragstart = function() { return false }

  function mouseDown(e) {
    isDown = true
    container.classList.add('active')
    startX = e.pageX - container.offsetLeft
    scrollLeft = container.scrollLeft
    timeoutPreventDefault = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    container.classList.remove('active')
  }

  function mouseup() {
    clearTimeout(timeoutPreventDefault)
    isDown = false
    container.classList.remove('active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - container.offsetLeft
    const walk = (x - startX) * 2
    container.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  function onResize() {
    const containerWidth = container.clientWidth
    const contentWidth = content.clientWidth
    const prevClass = `${options.name}__prev--is-active`
    const nextClass = `${options.name}__next--is-active`

    console.log(nextClass);

    if(contentWidth > containerWidth) {
      prev.classList.add(prevClass)
      next.classList.add(nextClass)
    } else {
      prev.classList.remove(prevClass)
      next.classList.remove(nextClass)
    }
    onScroll()
  }

  function onScroll() {
    const maxScrollLeft = container.scrollWidth - container.clientWidth
    const prevClass = `${options.name}__prev--is-visible`
    const nextClass = `${options.name}__next--is-visible`

    if(container.scrollLeft > 0) {
      prev.classList.add(prevClass)
    } else {
      prev.classList.remove(prevClass)
    }
    if(container.scrollLeft < maxScrollLeft) {
      next.classList.add(nextClass)
    } else {
      next.classList.remove(nextClass)
    }
  }

  onResize()
  onScroll()

  function setPosition(newPosition) {
    container.scrollLeft = container.scrollLeft + newPosition
  }

  function horizontaleScroll() {
    if (navigationIsDown) {
      if (direction === 'right')
        setPosition(15)
      else
        setPosition(-15)

      setTimeout(() => {
        requestAnimationFrame(horizontaleScroll)
      }, 1000 / 320)
    }
  }

  function mouseDownNext(e) {
    e.preventDefault()
    navigationIsDown = true
    direction = 'right'
    horizontaleScroll()
    return false
  }

  function mouseDownPrev(e) {
    e.preventDefault()
    navigationIsDown = true
    direction = 'left'
    horizontaleScroll()
    return false
  }

  function navigationIsDownFalse() {
    navigationIsDown = false
    return false
  }

  OBSERVER.add({ name: 'dragAndSlide', event: 'resize', function: onResize })
  OBSERVER.add({ name: 'dragAndSlide', event: 'scroll', function: onScroll, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDown, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: mouseleave, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: mouseup, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mousemove', function: mousemove, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'click', function: click, target: `.${options.contentClass} a` })
  OBSERVER.add({ name: 'dragAndSlide', event: 'click', function: click, target: `.${options.contentClass} a` })

  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDownNext, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchstart', function: mouseDownNext, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchend', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchcancel', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchleave', function: navigationIsDownFalse, target: next })

  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDownPrev, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchstart', function: mouseDownPrev, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchend', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchcancel', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchleave', function: navigationIsDownFalse, target: prev })
  OBSERVER.on('dragAndSlide')
}

export function recaptcha() {
  grecaptcha.ready(function() {
    grecaptcha.execute('6LcUzo8bAAAAAEcywSY6fCvpyhEsxa771TwIcEHE', {action: 'homepage'}).then( function(token) {
      $('#captcha_token_v3').val(token);
    });
  });
}
